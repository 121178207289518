import { PUBLIC_FEATURE_COURSES,
         PUBLIC_FEATURE_CREDENTIALS_LOGIN_ENABLED } from '$env/static/public';

class FeaturesFrameworkClientside {

    /** 
     * NOTE: Defaults to <true> if setting is not available - prefer permissive access to features.
     */
    private boolean(setting: string | undefined): boolean {
        return (setting === "" || setting === undefined) ? true : JSON.parse(setting);
    }

    is_courses_enabled(): boolean {
        // console.log("Public courses -> ", PUBLIC_FEATURE_COURSES, this.boolean(PUBLIC_FEATURE_COURSES), JSON.parse(PUBLIC_FEATURE_COURSES));
        return this.boolean(PUBLIC_FEATURE_COURSES);
    }

	is_credentials_login_enabled() {
        return this.boolean(PUBLIC_FEATURE_CREDENTIALS_LOGIN_ENABLED);
	}
}

const features_framework_clientside = new FeaturesFrameworkClientside();
export default features_framework_clientside;